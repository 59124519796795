import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['stripePublishableKey', 'basicPlan', 'proPlan', 'ultraPlan']

  chooseBasicPlan() {
    this.checkout(this.basicPlanTarget.dataset.priceId);
  }

  chooseProPlan() {
    this.checkout(this.proPlanTarget.dataset.priceId);
  }

  chooseUltraPlan() {
    this.checkout(this.ultraPlanTarget.dataset.priceId);
  }

  checkout(priceId) {
    const publishableKey = this.stripePublishableKeyTarget.dataset.stripePublishableKey
    const stripe = Stripe(publishableKey)

    fetch(`/subscriptions/checkout?plan=${priceId}`)
    .then(response => response.json())
    .then((json) => {
      stripe.redirectToCheckout({
        sessionId: json.session_id
      });
    });
  }
}